import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import { useAuthContext } from '../../../auth/useAuthContext';
import { filterNavConfigByRole } from '../../../utils/navConfig';
import Logo from '../../../components/logo';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10%',
    padding: theme.spacing(2, 2.5),
}));
export default function NavVertical({ openNav, onCloseNav }) {
    const { pathname } = useLocation();
    const { user } = useAuthContext();
    const filteredNavConfig = filterNavConfigByRole(navConfig, user?.roles[0]?.code, user?.roles);
    const isDesktop = useResponsive('up', 'lg');
    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    }, [pathname]);
    const renderContent = (_jsxs(Scrollbar, { sx: {
            height: 1,
            '& .simplebar-content': {
                height: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        }, children: [_jsxs(Stack, { spacing: 3, sx: {
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                }, children: [_jsx(Logo, {}), _jsx(NavAccount, {})] }), filteredNavConfig && _jsx(NavSectionVertical, { data: filteredNavConfig }), _jsx(Box, { sx: { flexGrow: 1 } })] }));
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD },
        }, children: [_jsx(NavToggleButton, {}), isDesktop ? (_jsx(Drawer, { open: true, variant: "permanent", PaperProps: {
                    sx: {
                        zIndex: 0,
                        width: NAV.W_DASHBOARD,
                        bgcolor: 'transparent',
                        borderRightStyle: 'dashed',
                    },
                }, children: renderContent })) : (_jsx(Drawer, { open: openNav, onClose: onCloseNav, ModalProps: {
                    keepMounted: true,
                }, PaperProps: {
                    sx: {
                        width: NAV.W_DASHBOARD,
                    },
                }, children: renderContent }))] }));
}
